<template>
  <div id="divVirtualVisitWrapper">
    <div class="content-layout-fixer">
      <div id="div-gpt-ad-1587046762079-0"></div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <div class="chef-banner" :style="{ 'background-image': chefBannerBg }"></div>
      </div>
    </div>
    <VueWrapperNarrow>
      <div v-if="status" class="player-wrapper">
        <iframe id="ommaPlayer" :src="url" allowfullscreen></iframe>
      </div>
      <div v-html="disclaimerHtml" class="legal-warning"></div>
      <div class="virtual-visit-content">
        <VueText sizeLevel="9" weightLevel="2" color="grey-40"
          >Performans takibi sayfandan işlerini takip et!
        </VueText>
      </div>
      <div class="work-button">
        <BrandButton
          as="router-link"
          class="click-btn"
          :target="{
            name: workRoute,
          }"
        >
          <VueIcon
            class="work-button-icon"
            :iconName="icons.briefcase.name"
            :width="icons.briefcase.width"
            :height="icons.briefcase.height"
            iconColor="#d02d2c"
          />
          <VueText color="grey-40" sizeLevel="7" weightLevel="5">Performans Takibi</VueText>
        </BrandButton>
      </div>
    </VueWrapperNarrow>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import gtmUtils from '@/mixins/gtmUtils.js';
import { VirtualVisit } from '@/services/Api/index';
import { mapGetters } from 'vuex';
import StorageHelper from '@/utils/storageHelper';
import { store } from '@/store/store';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { CONTENT_URL } from '@/constants/urls.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import StaticContent from '@/services/Api/staticContents';
import RosetteInfo from '@/mixins/rosetteInfo.js';

export default {
  name: 'VirtualVisit',
  mixins: [RosetteInfo, gtmUtils],
  data() {
    return {
      PERCENTAGE_CONSTANTS: {
        COMPLETION_THRESHOLD: 90,
        FULL_COMPLETION: 100,
        ROSETTE_TRIGGER_THRESHOLD: 80,
      },
      workRoute: RoutesSecure.ManageMyBusiness.name,
      url: null,
      disclaimerHtml: null,
      status: true,
      viewDuration: 0,
      virtualVisitPeriod: 0,
      scenes: {},
      lastWatchReport: null,
      eventLog: {
        customerId: null,
        timePeriod: null,
        duration: null,
        viewDuration: 0,
        templates: null,
        status: null,
        surveyResult: null,
      },
      customerAccumulatedPoints: 0,
      isFirstPlay: true,
    };
  },
  components: {
    VueText,
    BrandButton,
    VueWrapperNarrow,
    VueIcon,
  },
  computed: {
    ...mapGetters('app', ['getVirtualVisit']),
    icons() {
      return ICON_VARIABLES;
    },
    chefBannerBg() {
      return `url(${CONTENT_URL}/Files/VirtualVisit/chef-banner.png)`;
    },
  },
  created() {
    this.getVirtualVisitPeriod();
    this.getVirtualVisitFunc();
    this.$store.dispatch('app/setOverlayFunction', false);
  },
  mounted() {
    //initialize google ads
    this.pushAds('large');
    this.setCustomerAccumulatedPoints();
  },
  methods: {
    loadScriptFunc() {
      const ommaPlayer = document.getElementById('ommaPlayer');

      const resumeSession = () => {
        ommaPlayer.contentWindow.postMessage(
          JSON.stringify({
            event: 'resume-session',
            payload: this.scenes,
          }),
          '*',
        );
      };
      // Native Method, listens if VQ send a message
      window.addEventListener('message', event => {
        const isVqMessage = typeof event.data == 'string';
        if (!isVqMessage) return;

        const vqMessage = JSON.parse(event.data);
        console.log('vqPostMessage', vqMessage);
        if (vqMessage.event == 'ready-for-session-resume') resumeSession();
        this.createEventLogModel(vqMessage.event, vqMessage.payload);
        this.triggerRefreshRosettes(vqMessage.payload);
      });
    },
    triggerRefreshRosettes(payload) {
      if (
        payload &&
        payload.content &&
        payload.content.percentage >= this.PERCENTAGE_CONSTANTS.ROSETTE_TRIGGER_THRESHOLD
      ) {
        this.getRosettesList();
        this.getRosetteRewardStatu();
      }
    },
    getVirtualVisitPeriod() {
      StaticContent.getStaticContent('VirtualVisitPeriod').then(res => {
        let {
          Data: { content },
        } = res.data;
        this.virtualVisitPeriod = content;
      });
    },

    getVirtualVisitFunc() {
      let taskCount = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_TASK_COUNT,
      }).get();

      VirtualVisit.getVirtualVisit().then(res => {
        const url = `${res.data.Data.url}&watchReportInterval=${+this
          .virtualVisitPeriod}&ts=${Date.now()}&points=${
          this.customerAccumulatedPoints
        }&yapKazan=${taskCount}`;

        console.log({ virtualVisitUrl: url });

        const disclaimerHtml = res.data.Data.disclaimerHtml;
        this.scenes = res.data.Data.scenes;
        this.disclaimerHtml = disclaimerHtml;
        this.url = url;
        this.$store.dispatch('app/setVirtualVisit', url);
        this.loadScriptFunc();
      });
    },
    virtualVisitValidateFunc() {
      VirtualVisit.virtualVisitValidate({ ...this.eventLog }).then(res => {
        const data = res.data.Data.isValidUser;
        this.status = data;
      });
    },
    saveEventLog() {
      VirtualVisit.virtualVisitSaveLog({ ...this.eventLog }).then(res => {
        if (!res.data.Data) return;
        let userDetails = window.insider_object?.user?.custom
          ? { ...window.insider_object.user.custom }
          : new StorageHelper({
              id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
            }).get();
        userDetails.virtualVisit = res.data.Data.virtualVisit;
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL }).set(userDetails);
        store.dispatch('auth/setUserDetails', userDetails);
        if (window.insider_object?.user) window.insider_object.user.custom = userDetails;
        if (this.eventLog.status === 3) {
          this.pushDataLayerEvent('virtualVisit', {
            ...userDetails.virtualVisit,
          });
        }
      });
    },
    createEventLogModel(eventName, payload) {
      switch (eventName) {
        case 'play':
          this.dataLayerVideoEvent('Play');
          break;
        case 'pause':
          this.dataLayerVideoEvent('Pause');
          break;
        case 'fullscreen_enter':
          this.setFullScreen();
          break;
        case 'fullscreen_exit':
          this.removeFullScreen();
          break;
        case 'seek-end':
          this.dataLayerVideoEvent('Seeked');
          break;
        case 'watch-report':
          this.lastWatchReport = payload;
          this.virtualVisitSaveScenes(payload);
          this.setDuration(payload);
          this.setTemplates(payload);
          this.setStartedLog(payload);
          break;
        case 'finished':
          this.setFinishedLog();
          this.dataLayerVideoEvent('Watch to End');
          break;
        case 'ended':
          this.setEndedLog(payload);
          this.dataLayerVideoEvent('Watch to End');
          break;
        case 'custom':
          this.setCustomLog(payload);
          break;
        case 'action':
          if (payload.name === 'surveyResult') {
            this.setSurveyLog(payload);
          }
          break;
        case 'scene-change':
          this.setSceneChange(payload);
          break;
        case 'error':
          this.saveError(payload);
          break;
      }
    },
    dataLayerVideoEvent(action) {
      this.pushDataLayerEvent('video', {
        name: 'virtual visit',
        action: action,
        url: this.url || '',
      });
    },
    setSceneChange() {
      this.saveEventLog();
    },
    setCustomLog(payload) {
      if (payload.name === 'session_details') {
        this.setCustomerIdLog(payload);
      }
    },
    setCustomerIdLog(payload) {
      this.eventLog.customerId = payload.customer_id;
      const startDate = payload.timePeriod.startDate
        .split('/')
        .reverse()
        .join('-');
      this.eventLog.timePeriod = startDate;
      this.virtualVisitValidateFunc();
    },
    setDuration(payload) {
      this.eventLog.duration = parseInt(payload.content.totalTime);
      this.eventLog.viewDuration = parseInt(
        (this.eventLog.duration * parseInt(payload.content.percentage)) / 100,
      );
      if (payload.content.percentage != 0) {
        this.eventLog.status = 4;
      }
    },
    setTemplates(payload) {
      this.eventLog.templates = Object.values(payload.scenes)
        .map(q => q.title.replace('Template', '').trim())
        .join(',');
    },

    setStartedLog(payload) {
      if (payload.content.percentage == 0 || this.isFirstPlay) {
        this.eventLog.duration = parseInt(payload.content.totalTime);
        this.eventLog.status = 1;
      }
      this.isFirstPlay = false;
    },
    setSurveyLog(payload) {
      this.eventLog.status = 2;
      this.eventLog.surveyResult = +payload.value;
      this.saveEventLog();
    },
    setEndedLog() {
      this.eventLog.status = 3;
      this.saveEventLog();
    },
    setFinishedLog() {
      if (this.lastWatchReport) {
        let payloadToUse = this.lastWatchReport;

        // Only set to 100% if actual progress is above threshold
        if (
          this.lastWatchReport.content.percentage > this.PERCENTAGE_CONSTANTS.COMPLETION_THRESHOLD
        ) {
          payloadToUse = {
            ...this.lastWatchReport,
            content: {
              ...this.lastWatchReport.content,
              percentage: this.PERCENTAGE_CONSTANTS.FULL_COMPLETION,
            },
          };
        }

        this.virtualVisitSaveScenes(payloadToUse);
        this.setDuration(payloadToUse);
        this.setTemplates(payloadToUse);
      }

      this.eventLog.status = 3;
      this.saveEventLog();
    },
    saveError(payload) {
      let errorObj = {
        type: payload.type,
        message: payload.message,
      };
      VirtualVisit.VirtualVisitException({ ...errorObj });
    },
    virtualVisitSaveScenes(payload) {
      const request = {
        scenes: {
          content: payload.content,
          scenes: payload.scenes,
        },
      };

      VirtualVisit.virtualVisitSaveScenes(request);
    },
    setFullScreen() {
      const iframe = document.getElementById('ommaPlayer');
      iframe.classList.add('fullscreen');
    },
    removeFullScreen() {
      const iframe = document.getElementById('ommaPlayer');
      iframe.classList.remove('fullscreen');
    },
    setCustomerAccumulatedPoints() {
      const customerDetail = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_POS_DETAIL,
      }).get();

      customerDetail?.partners?.forEach(f => {
        if (f.code != 'PMI') return;
        this.customerAccumulatedPoints = f.point;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content-row {
  height: initial;
  width: 100%;
}
.chef-banner {
  position: relative;
  background-size: cover;
  height: 344px;
  margin-left: 20px;
}
.video-wrapper {
  max-height: 361px;
  margin: 60px auto 0;
}
.virtual-visit-content {
  margin: 30px auto;
}
.margin-top-10 {
  margin-top: 10px;
}
.work-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  .click-btn {
    width: 50% !important;
    border-radius: 5px;
    padding: 1rem;
    font-family: 'Avenir Next';
    font-size: 1rem;
    padding: #000;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(to left, #d20051, #ffa300);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
  }
  &-icon {
    margin-right: palette-space-level(12);
  }
}
.fullscreen {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999;
}
.player-wrapper {
  margin-top: 60px;
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  #ommaPlayer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.legal-warning {
  padding: palette-space-level(15) palette-space-level(30);
  border: solid 2px #000;
  margin-top: palette-space-level(15);
  /deep/ul {
    li {
      font-size: palette-font-size-level('4');
    }
  }
}
</style>
